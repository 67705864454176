import React, { useContext } from "react"
import { graphql } from "gatsby"

import { UserContext } from "../helpers/context"

import CardService from "../components/cards/CardService"
import SideMenue from "../components/SideMenu"

export default props => {
  const { data } = props
  const { younion } = data
  const { promotionCategories } = younion

  const user = useContext(UserContext)
  const promoCatAllowed = promotionCategories.filter(
    promo => !promo.membersOnly || user?.sessionId
  )

  const serviceWithoutParent = promoCatAllowed.filter(
    promo => !promo.parent.length
  )

  return (
    <div>
      <h1>Service</h1>
      <div className="service">
        <div className="service-left">
          <SideMenue categories={promoCatAllowed} />
        </div>
        <div className="service-right">
          <div className="card-grid">
            {serviceWithoutParent.map(service => (
              <CardService key={service._id} path="/service" {...service} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      promotionCategories {
        ...promotionCategoryFields
        children {
          ...promotionCategoryCards
          children {
            ...promotionCategoryCards
          }
        }
        parent {
          ...promotionCategoryCards
        }
        promotions {
          ...promotionCards
        }
      }
    }
  }
`
